import { setAlert } from "./alert";

import {
  GET_PROFILE,
  GET_PROFILES,
  PROFILE_ERROR,
  // UPDATE_PROFILE,
  CLEAR_PROFILE,
  ACCOUNT_DELETED,
  AVATAR_UPLOAD,
  PROFILE_LOADING,
  GET_MY_PROFILE,
} from "./types";
import { Storage } from "@aws-amplify/storage";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { updateUser, deleteUser } from "../graphql/mutations.ts";
import { getUser, listUsers } from "../graphql/queries.ts";

//Get all profiles
export const getProfiles = () => async (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });

  //api/profile
  try {
    // const res = await axios.get("/api/profile"); //knows user by token

    //list all users
    const users = await API.graphql(graphqlOperation(listUsers));

    dispatch({
      type: GET_PROFILES,
      payload: users.data.listUsers.items,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Get profile by user id
export const getProfileById = (userId) => async (dispatch) => {
  try {
    //TODO get GET_OTHER_USER_PROFILE
    // dispatch({
    //   type: CLEAR_PROFILE,
    // });
    dispatch({
      type: PROFILE_LOADING,
    });
    const profile = await API.graphql(
      graphqlOperation(getUser, { id: userId })
    );

    //TODO get GET_OTHER_USER_PROFILE
    dispatch({
      type: GET_PROFILE,
      payload: profile.data.getUser,
    });
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status },
    });
  }
};

//Get memecoins by user id
// export const getMemecoins = (userId) => async (dispatch) => {
//   //route GET api/profile/user/:id/memecoins
//   try {
//     const res = await axios.get(`/api/profile/user/${userId}/memecoins`);

//     //TODO get GET_OTHER_USER_PROFILE
//     dispatch({
//       type: GET_PROFILE,
//       payload: { userId, memecoins: res.data },
//     });
//   } catch (err) {
//     dispatch({
//       type: PROFILE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status },
//     });
//   }
// };

//Update memecoins
// export const updateMemecoins = (user) => async (dispatch) => {
//   //route GET api/profile/user/:id/memecoins
//   try {
//     // const res = await axios.put(`/api/profile/user/${userId}/memecoins`);
//     const res = await API.graphql(
//       graphqlOperation(updateUser, {
//         input: {
//           id: userId,
//           memecoins: 100,
//         },
//       }))

//     dispatch({
//       type: UPDATE_PROFILE,
//       payload: { userId, memecoins: res.data },
//     });
//   } catch (err) {
//     dispatch({
//       type: PROFILE_ERROR,
//       payload: { msg: err.response.statusText, status: err.response.status },
//     });
//   }
// };

//Create or update profile
export const updateProfile = (data, id) => async (dispatch) => {
  try {
    const { facebook, twitter, instagram, youtube, linkedin } = data;
    const social = { facebook, twitter, instagram, youtube, linkedin };
    //graphql update
    const res = await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: id,
          bio: data.bio,
          location: data.location,
          website: data.website,
          favourite: data.favourite,
          social,
        },
      })
    );

    dispatch({
      type: GET_MY_PROFILE,
      payload: res.data.updateUser,
    });
    dispatch(setAlert("Profile Updated", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: "error updating profile", status: "404" },
    });
  }
};

//Delete Account and Profile
export const deleteAccount = (username) => async (dispatch) => {
  if (window.confirm("Are you sure? This can NOT be undone!")) {
    try {
      await API.graphql(
        graphqlOperation(deleteUser, { input: { id: username } })
      );
      await Auth.deleteUser();

      dispatch({
        type: CLEAR_PROFILE,
      });
      dispatch({
        type: ACCOUNT_DELETED,
      });
      dispatch(setAlert("Your accont has gone", "success"));
    } catch (err) {
      console.log(err);
    }
  }
};

//change name
export const changeUsername = (username, userId) => async (dispatch) => {
  try {
    if (!username || !userId) throw new Error("Incorrect username");

    const res = await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: userId,
          name: username,
        },
      })
    );

    dispatch({
      type: GET_MY_PROFILE,
      payload: res.data.updateUser,
    });
    dispatch(setAlert("Profile Updated", "success"));

    dispatch(setAlert("Your username has been changed", "success"));
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: "error updating profile", status: "404" },
    });
  }
};

// Upload Avatar
export const uploadAvatar = (image, userId) => async (dispatch) => {
  try {
    if (!image) throw new Error("No image selected");

    const avatarUrl = await Storage.put(
      `avatars/${userId}/${image.name}`,
      image
    );
    console.log(avatarUrl);
    const res = await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: userId,
          avatar: avatarUrl.key,
        },
      })
    );
    dispatch({
      type: GET_MY_PROFILE,
      payload: res.data.updateUser,
    });

    dispatch({
      type: AVATAR_UPLOAD,
      payload: res.data,
    });

    dispatch(setAlert("Avatar Uploaded", "success"));
  } catch (err) {
    dispatch({
      type: PROFILE_ERROR,
      payload: { msg: "Image upload error", status: 404 },
    });
  }
};
