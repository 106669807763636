/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      posts {
        nextToken
      }
      location
      favourite
      bio
      social {
        youtube
        twitter
        facebook
        linkedin
        instagram
      }
      date
      memecoins
      website
      avatar
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        location
        favourite
        bio
        date
        memecoins
        website
        avatar
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      type
      image
      text
      name
      avatar
      likes
      dislikes
      comments {
        user
        text
        name
        avatar
        date
      }
      score
      categories
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        image
        text
        name
        avatar
        likes
        dislikes
        score
        categories
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const postsByUserID = /* GraphQL */ `
  query PostsByUserID(
    $userID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    postsByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        image
        text
        name
        avatar
        likes
        dislikes
        score
        categories
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
