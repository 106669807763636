import React from "react";
import { changeTheme } from "../../actions/theme";
import { connect } from "react-redux";

//action type
//export const CHANGE_THEME = "CHANGE_THEME";
const Theme = ({ changeTheme, theme }) => {
  return (
    <button
      className="btn btn-light"
      id="theme"
      onClick={() => {
        if (theme === "light" || !theme) {
          theme = "dark";
          //set document
          document.body.className = theme;
        } else {
          theme = "light";
          //set document
          document.body.className = theme;
        }
        changeTheme(theme);
      }}
    >
      Change Theme
    </button>
  );
};

const mapStateToProps = state => ({
  theme: state.theme.mode
});

export default connect(mapStateToProps, { changeTheme })(Theme);