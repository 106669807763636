import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { uploadAvatar } from "../../actions/profile";

const AvatarForm = ({ uploadAvatar, user }) => {
  const [image, setImage] = useState([]);

  const uploadUserAvatar = async (e, image, userId) => {
    e.preventDefault();
    console.log(image);
    await uploadAvatar(image, userId);
  };

  return (
    <div className="post-form bg p-2 mt-1">
      <div className="bg-primary p">
        <h3>Upload your avatar here</h3>
      </div>
      <form
        encType="multipart/form-data"
        className="form mt-1"
        onSubmit={
          (e) => uploadUserAvatar(e, image, user.username)
          //     e => {
          //     //stop it from continuing to the next page you probably need to
          //     e.preventDefault();
          //     //bodyFormData.set('text', '');
          //     bodyFormData.append('image', image);
          //     //sending image first
          //     axios({
          //         method: 'post',
          //         url: 'api/users/images',
          //         data: bodyFormData,
          //         headers: {
          //             'accept': 'application/json',
          //             'content-type': 'application/json'
          //         },
          //     })
          //         //getting image url once uploaded and passing to auploadAvatar()
          //         .then(function (response) {
          //             const image = {
          //                 json: true,
          //                 data: {
          //                     id: response.data.id,
          //                     url: response.data.url
          //                 }
          //             };

          //             setImage((image.id = response.data.id, image.url = response.data.url));

          //             //handle success
          //             return uploadAvatar(image); //console.log(text, image);

          //         })
          //         .catch(function (response) {
          //             //handle error
          //             console.log(response);
          //         });
          //     //clear Img
          //     //setImage([]);
          // }
        }
      >
        <input
          type="file"
          className={image.length !== 0 ? "bg-success" : " "}
          name="image"
          onChange={(e) => setImage(e.target.files[0])}
        />
        <input type="submit" className="btn btn-dark my-1" value="Submit" />
      </form>
    </div>
  );
};

AvatarForm.propTypes = {
  uploadAvatar: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { uploadAvatar })(
  withRouter(AvatarForm)
);
