import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'; //every time we want interact component with redux, we want to use connect

const Alert = ({ alerts }) =>
  //check that alerts not null and we actually have something to output in our array
  alerts !== null &&
  alerts.length > 0 &&
  alerts.map(alert => (
    //output alert
    <div key={alert.id} className={`alert alert-${alert.alertType}`}>
      {alert.msg}
    </div>
  ));

Alert.propTypes = {
  alert: PropTypes.array
};

const mapStateToProps = state => ({
  alerts: state.alert
}); //map redux  state to a prop in this component (array of alerts)

export default connect(mapStateToProps)(Alert);
