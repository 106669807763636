//ALERTS
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//AUTH & USERS
export const EMAIL_VERIFICATION_PENDING = "EMAIL_VERIFICATION_PENDING";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const SET_AUTH = "SET_AUTH";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const ACCOUNT_DELETED = "ACCOUNT_DELETED";
export const CHANGE_USERNAME = "CHANGE_USERNAME";
export const AVATAR_UPLOAD = "AVATAR_UPLOAD";

//PROFILE
export const GET_MY_PROFILE = "GET_MY_PROFILE";
export const GET_PROFILE = "GET_PROFILE";
export const GET_PROFILES = "GET_PROFILES";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const PROFILE_ERROR = "PROFILE_ERROR";
export const CLEAR_PROFILE = "CLEAR_PROFILE";
export const PROFILE_LOADING = "PROFILE_LOADING";

//POSTS
export const GET_POSTS = "GET_POSTS";
export const GET_POST = "GET_POST";
export const POST_ERROR = "POST_ERROR";
export const UPDATE_LIKES = "UPDATE_LIKES";
export const DELETE_POST = "DELETE_POST";
export const ADD_POST = "ADD_POST";
export const ADD_COMMENT = "ADD_COMMENT";
export const REMOVE_COMMENT = "REMOVE_COMMENT";
export const UPDATE_DISLIKES = "UPDATE_DISLIKES";
export const CLEAR_POSTS = "CLEAR_POSTS";

//LAYOUT
export const CHANGE_THEME = "CHANGE_THEME";

//TODO ADD LOADINGS
