import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logout } from "../../actions/auth";
import logo from "../../img/memefluencer-logo.png";
import { Auth } from "aws-amplify";

const Navbar = ({ auth: { isAuthenticated, loading }, logout }) => {
  const signOut = async () => {
    try {
      await Auth.signOut({ global: true });
      logout();
      console.log("signed out");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const authLinks = (
    <>
      <li>
        <Link to="/posts">Memes</Link>
      </li>
      {/* <li>
        <Link to="/free-speech">Free speech</Link>
      </li> */}
      <li>
        <Link to="/profiles">Memefluencers</Link>
      </li>
      <li>
        <Link to="/dashboard">
          <i className="fas fa-user hidden show-sm"> </i> {"  "}
          <span>Dashboard</span>
        </Link>
      </li>
      <li>
        <Link onClick={signOut} to="/">
          <i className="fas fa-sign-out-alt hidden show-sm"> </i> {"  "}
          <span>Logout</span>
        </Link>
      </li>
    </>
  );

  const guestLinks = (
    <>
      <li>
        <Link to="/posts">Memes</Link>
      </li>
      {/* <li>
        <Link to="/free-speech">Free speech</Link>
      </li> */}
      <li>
        <Link to="/profiles">Memefluencers</Link>
      </li>
      <li>
        <Link to="/dashboard">Register / Login</Link>
      </li>
    </>
  );

  const [toggle, setToggle] = useState(false);

  return (
    <nav className="navbar bg-dark">
      <div className="navbar-wrapper">
        <Link to="/">
          <img src={logo} alt="Memefluencer" className="logo"></img>
        </Link>
        <button className="btn-toggle-menu" onClick={() => setToggle(!toggle)}>
          <i className="fas fa-bars"></i>
        </button>
      </div>

      <Fragment>
        <ul
          className={
            toggle
              ? "navbar-menu navbar-menu-show"
              : "navbar-menu navbar-menu-hidden"
          }
        >
          {isAuthenticated ? authLinks : guestLinks}
        </ul>
      </Fragment>
    </nav>
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logout })(Navbar);
