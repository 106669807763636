import React, { Fragment, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import ProfileTop from "./ProfileTop";
import ProfilePosts from "./ProfilePosts";
import { getProfileById } from "../../actions/profile";

const Profile = ({
  getProfileById,
  profile,
  auth,
  match,
  //add posts by profile
  // posts,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      console.log("match.params.id)", match.params.id);
      if (!match.params.id) return setIsLoading(false);
      await getProfileById(match.params.id);
      setIsLoading(false);
    })();
  }, [getProfileById, match.params.id]);

  if (auth && auth.user && auth.user.username === match.params.id)
    return <Redirect to="/dashboard" />;

  return (
    <Fragment>
      {isLoading ? (
        <Spinner></Spinner>
      ) : (
        <Fragment>
          <Link to="/profiles" className="btn btn-light">
            Back
          </Link>
          {profile.profile === null ? (
            <></>
          ) : (
            <div className="profile-grid my-1">
              <ProfileTop profile={profile.profile} />
              {/* all user's posts go here */}
              <ProfilePosts profile={profile.profile} />
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

Profile.propTypes = {
  getProfileById: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  auth: state.auth, //see if user is logged in and sees herself profile
});

export default connect(mapStateToProps, { getProfileById })(Profile);
