import { API, graphqlOperation } from "aws-amplify";
import { createUser } from "../graphql/mutations.ts";
import { getUser } from "../graphql/queries.ts";

import {
  SET_AUTH,
  AUTH_ERROR,
  LOGOUT,
  CLEAR_PROFILE,
  GET_MY_PROFILE,
} from "./types";

export const loadUser = (username, attributes) => async (dispatch) => {
  try {
    //get user with username
    //console.log("loadUser username", username, attributes);
    const user = await API.graphql(graphqlOperation(getUser, { id: username }));
    //console.log("loadUser user", user);

    if (!user || user.data.getUser === null) {
      //create user
      const newUser = await API.graphql(
        graphqlOperation(createUser, {
          input: {
            id: username,
            name: attributes.name,
            avatar: "avatars/defaultAvatar.png",
          },
        })
      );
      dispatch({
        type: GET_MY_PROFILE,
        payload: newUser.data.createUser,
      });
    } else {
      console.log("loadUser user", user.data.getUser);
      dispatch({
        type: GET_MY_PROFILE,
        payload: user.data.getUser,
      });
    }

    dispatch({
      type: SET_AUTH,
      payload: { attributes, username },
    });
  } catch (err) {
    console.log("loadUser error", err);
    dispatch({
      type: AUTH_ERROR,
    });
  }
};
//logout / clear Profile
export const logout = () => (dispatch) => {
  dispatch({ type: CLEAR_PROFILE });
  dispatch({ type: LOGOUT });
};
