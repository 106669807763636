import uuid from 'uuid';
import { SET_ALERT, REMOVE_ALERT } from './types';

//we call this function from a component
export const setAlert = (msg, alertType, timeout = 3500) => dispatch => {
  const id = uuid.v4();
  dispatch({
    //dispatch type set alert to a reducer
    type: SET_ALERT,
    payload: { msg, alertType, id } //payload to pass to alert.js
  });

  setTimeout(() => dispatch({ type: REMOVE_ALERT, payload: id }), timeout); //after few seconds remove alert
};
