import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Storage } from "aws-amplify";

const ProfileItem = ({
  profile: { id, name, avatar, location, favourite, memecoins },
}) => {
  const [avatarImg, setAvatarImg] = useState(null);

  useEffect(() => {
    async function fetchAvatar() {
      const avatarImg = await Storage.get(avatar);
      setAvatarImg(avatarImg);
    }
    fetchAvatar();
  }, [avatar]);

  return (
    <div className="profile outline-light">
      <img
        src={avatarImg}
        alt="Memefluencer's Avatar"
        className="round-img"
        width={250}
        height={250}
      />
      <div>
        <h2>{name}</h2>
        <p></p>
        <p className="mt-1">{location && <span>{location}</span>}</p>
        <p className="mb-1">
          {memecoins && <span>Memecash: {memecoins}</span>}
        </p>
        <Link to={`/profile/${id}`} className="btn btn-primary">
          View Profile
        </Link>
      </div>
    </div>
  );
};

ProfileItem.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileItem;
