import {
  GET_PROFILE,
  GET_MY_PROFILE,
  AVATAR_UPLOAD,
  PROFILE_ERROR,
  CLEAR_PROFILE,
  UPDATE_PROFILE,
  GET_PROFILES,
  PROFILE_LOADING,
  CHANGE_USERNAME,
} from "../actions/types";

const initialState = {
  myProfile: null, //for personal profile
  profile: null, //for other profiles
  profiles: [], //for list of profiles
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_PROFILE:
    case GET_PROFILE:
      console.log("GET_PROFILE", payload);
      return {
        ...state,
        profile: payload,
        loading: false,
      };
    case GET_MY_PROFILE:
      console.log("GET_MY_PROFILE", payload);
      return {
        ...state,
        myProfile: payload,
        loading: false,
      };
    case GET_PROFILES:
      return {
        ...state,
        profiles: payload,
        loading: false,
      };
    case PROFILE_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case CLEAR_PROFILE:
      return {
        ...state,
        profile: null,
      };
    case PROFILE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case AVATAR_UPLOAD:
    case CHANGE_USERNAME:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}
