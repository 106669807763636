import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changeUsername } from "../../actions/profile";

const ChangeUsername = ({ changeUsername, myProfile }) => {
  const [username, setUsername] = useState("");
  useEffect(() => {
    if (myProfile) {
      setUsername(myProfile.name);
    }
  }, [myProfile]);

  const onSubmit = (e) => {
    e.preventDefault();
    changeUsername(username, myProfile.id);
  };

  return (
    <>
      <div className="bg p-2 my-1">
        <h1 className="text-primary">Change username</h1>
        <form className="form" onSubmit={(e) => onSubmit(e)}>
          <div className="form-group">
            <input
              type="text"
              placeholder="Enter new username here"
              name="name"
              maxLength="25"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <input type="submit" className="btn btn-primary my-1" />
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  myProfile: state.profile.myProfile,
});

ChangeUsername.propTypes = {
  changeUsername: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
};
export default connect(mapStateToProps, { changeUsername })(
  withRouter(ChangeUsername)
);
