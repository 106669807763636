import { setAlert } from "./alert";
import { Storage } from "@aws-amplify/storage";
import {
  GET_POSTS,
  POST_ERROR,
  UPDATE_LIKES,
  UPDATE_DISLIKES,
  DELETE_POST,
  ADD_POST,
  GET_POST,
  ADD_COMMENT,
  REMOVE_COMMENT,
  CLEAR_POSTS,
} from "./types";
import { API, graphqlOperation } from "aws-amplify";
import {
  createPost,
  updatePost,
  updateUser,
  deletePost,
} from "../graphql/mutations.ts";
import {
  listPosts,
  postsByUserID,
  getUser,
  getPost,
} from "../graphql/queries.ts";
//Get posts
export const getPosts = () => async (dispatch) => {
  try {
    dispatch({ type: CLEAR_POSTS });
    const res = await API.graphql(graphqlOperation(listPosts));
    console.log("getPosts res: ", res.data.listPosts.items);
    dispatch({
      type: GET_POSTS,
      payload: { userId: "ALL", posts: res.data.listPosts.items },
    });
  } catch (err) {
    console.log("getPosts error: ", err);
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: "Error getting posts",
        status: "404",
      },
    });
  }
};

//Get all posts and stoies by User ID
export const getAllPostsByUserId = (userId) => async (dispatch) => {
  try {
    // dispatch({ type: CLEAR_POSTS });
    // const res = await axios.get(`/api/posts/all/user/${userId}`);
    const posts = await API.graphql(
      graphqlOperation(postsByUserID, { userID: userId })
    );
    console.log("getAllPostsByUserId posts: ", posts);
    dispatch({
      type: GET_POSTS,
      payload: { userId, posts: posts.data.postsByUserID.items },
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: "Error getting posts",
        status: 404,
      },
    });
  }
};

//Add Like
export const addLike = (id, postOwner, likerId) => async (dispatch) => {
  console.log("addLike id: ", id, postOwner, likerId);
  try {
    //get original post
    let post = await API.graphql(graphqlOperation(getPost, { id }));
    post = post.data.getPost;
    console.log(post);

    //check if already disliked
    if (post.dislikes.includes(likerId)) {
      await dispatch(removeDislike(id, postOwner, likerId, post));
    }
    //update dislikes and score
    if (post.likes.includes(likerId)) {
      console.log("already liked");
      await dispatch(removeLike(id, postOwner, likerId, post));
    } else {
      const res = await API.graphql(
        graphqlOperation(updatePost, {
          input: {
            id,
            likes: [...post.likes, likerId],
            score: Number(post.score) + 1,
          },
        })
      );
      console.log("addLike res: ", res);
      let owner = await API.graphql(
        graphqlOperation(getUser, {
          id: postOwner,
        })
      );
      owner = owner.data.getUser;

      if (owner) {
        await API.graphql(
          graphqlOperation(updateUser, {
            input: { id: postOwner, memecoins: Number(owner.memecoins) + 1 },
          })
        );
      }

      dispatch({
        type: UPDATE_LIKES,
        payload: { id, likes: res.data.updatePost.likes, userId: postOwner },
      });
    }

    //update owner's memecoins
  } catch (err) {
    console.log("addLike error: ", err);
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: "Error liking post",
        status: 404,
      },
    });
  }
};

//Add disLike
export const addDislike = (id, postOwner, likerId) => async (dispatch) => {
  try {
    //get original post
    let post = await API.graphql(graphqlOperation(getPost, { id }));
    post = post.data.getPost;
    //check if already liked
    if (post.likes.includes(likerId)) {
      await dispatch(removeLike(id, postOwner, likerId, post));
    }
    if (post.dislikes.includes(likerId)) {
      await dispatch(removeDislike(id, postOwner, likerId, post));
    } else {
      //update dislikes and score
      const res2 = await API.graphql(
        graphqlOperation(updatePost, {
          input: {
            id,
            dislikes: [...post.dislikes, likerId],
            score: Number(post.score) + 1,
          },
        })
      );
      console.log("addDislike res2: ", res2);
      //update owner's memecoins
      let owner = await API.graphql(
        graphqlOperation(getUser, {
          id: postOwner,
        })
      );
      owner = owner.data.getUser;
      if (owner) {
        await API.graphql(
          graphqlOperation(updateUser, {
            input: { id: postOwner, memecoins: Number(owner.memecoins) + 1 },
          })
        );
      }

      dispatch({
        type: UPDATE_DISLIKES,
        payload: {
          id,
          dislikes: res2.data.updatePost.dislikes,
          userId: postOwner,
        },
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: "Error disliking post",
        status: 404,
      },
    });
  }
};

//Remove like
export const removeLike =
  (id, postOwner, likerId, post) => async (dispatch) => {
    try {
      //update likes and score
      const res = await API.graphql(
        graphqlOperation(updatePost, {
          input: {
            id,
            likes: post.likes.filter((id) => id !== likerId),
            score: Number(post.score) - 1,
          },
        })
      );
      console.log("removeLike res: ", res);
      //update owner's memecoins
      let owner = await API.graphql(
        graphqlOperation(getUser, {
          id: postOwner,
        })
      );
      owner = owner.data.getUser;
      if (owner) {
        await API.graphql(
          graphqlOperation(updateUser, {
            input: { id: postOwner, memecoins: Number(owner.memecoins) - 1 },
          })
        );
      }

      dispatch({
        type: UPDATE_LIKES,
        payload: { id, likes: res.data.updatePost.likes, userId: postOwner },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: POST_ERROR,
        payload: {
          msg: "Error removing like",
          status: 404,
        },
      });
    }
  };

//Remove dislike
export const removeDislike =
  (id, postOwner, likerId, post) => async (dispatch) => {
    try {
      //update dislikes and score
      const res = await API.graphql(
        graphqlOperation(updatePost, {
          input: {
            id,
            dislikes: post.dislikes.filter((id) => id !== likerId),
            score: Number(post.score) - 1,
          },
        })
      );
      console.log("removeDislike res: ", res);
      //update owner's memecoins
      let owner = await API.graphql(
        graphqlOperation(getUser, {
          id: postOwner,
        })
      );
      owner = owner.data.getUser;
      if (owner) {
        await API.graphql(
          graphqlOperation(updateUser, {
            input: { id: postOwner, memecoins: Number(owner.memecoins) - 1 },
          })
        );
      }

      dispatch({
        type: UPDATE_DISLIKES,
        payload: {
          id,
          dislikes: res.data.updatePost.dislikes,
          userId: postOwner,
        },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: POST_ERROR,
        payload: {
          msg: "Error removing dislike",
          status: 404,
        },
      });
    }
  };

// Delete post
export const deletePostById = (id, userId) => async (dispatch) => {
  if (window.confirm("Are you sure? This can NOT be undone!")) {
    try {
      await API.graphql(
        graphqlOperation(deletePost, {
          input: { id },
        })
      );

      dispatch({
        type: DELETE_POST,
        payload: { userId, postId: id },
      });
      dispatch(setAlert("Post Removed", "success"));
    } catch (err) {
      console.log(err);
      dispatch({
        type: POST_ERROR,
        payload: {
          msg: "Error deleting post",
          status: 404,
        },
      });
    }
  }
};

// Add post
export const addPost =
  (text, image, type, categories, user) => async (dispatch) => {
    try {
      const post = await Storage.put(`memes/${user.id}-${image.name}`, image);
      console.log(post);
      const res = await API.graphql(
        graphqlOperation(createPost, {
          input: {
            // id: userId,
            text: text,
            image: post.key,
            type: type,
            categories: categories,
            userID: user.id,
            name: user.name,
            avatar: user.avatar,
            likes: [],
            dislikes: [],
            comments: [],
            score: 0,
          },
        })
      );
      console.log(res);
      dispatch({
        type: ADD_POST,
        payload: { newPost: res.data.createPost, userId: user.id },
      });

      dispatch(setAlert("Post Created", "success"));
    } catch (err) {
      console.log(err);
      dispatch({
        type: POST_ERROR,
        payload: {
          msg: "Error creating post",
          status: 404,
        },
      });
    }
  };

//Get post
export const getPostById = (id) => async (dispatch) => {
  try {
    let post = await API.graphql(graphqlOperation(getPost, { id }));
    post = post.data.getPost;

    dispatch({
      type: GET_POST,
      payload: post,
    });
  } catch (err) {
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: "Error getting post",
        status: 404,
      },
    });
  }
};

// Add Comment
export const addComment = (id, text, userPoster) => async (dispatch) => {
  try {
    console.log("addComment: ", id, text, userPoster);
    let post = await API.graphql(graphqlOperation(getPost, { id }));
    post = post.data.getPost;
    const res = await API.graphql(
      graphqlOperation(updatePost, {
        input: {
          id,
          comments: [
            ...post.comments,
            {
              text: text,
              name: userPoster.name,
              avatar: userPoster.avatar,
              user: userPoster.id,
              //AWSDateTime
              date: new Date(),
            },
          ],
          score: Number(post.score) + 1,
        },
      })
    );

    dispatch({
      type: ADD_COMMENT,
      payload: res.data.updatePost.comments,
    });

    dispatch(setAlert("Comment Added", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: "Error adding comment",
        status: 404,
      },
    });
  }
};

// Delete Comment
export const deleteComment = (id, commentId) => async (dispatch) => {
  try {
    //await axios.delete(`/api/posts/comment/${postId}/${commentId}`);

    let post = await API.graphql(graphqlOperation(getPost, { id }));
    post = post.data.getPost;

    await API.graphql(
      graphqlOperation(updatePost, {
        input: {
          id,
          comments: post.comments.filter((comment) => comment.id !== commentId),
          score: Number(post.score) - 1,
        },
      })
    );

    dispatch({
      type: REMOVE_COMMENT,
      payload: commentId,
    });

    dispatch(setAlert("Comment Removed", "success"));
  } catch (err) {
    console.log(err);
    dispatch({
      type: POST_ERROR,
      payload: {
        msg: "Error deleting comment",
        status: 404,
      },
    });
  }
};
