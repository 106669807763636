import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import {
  deletePostById,
  addLike,
  removeLike,
  addDislike,
  removeDislike,
} from "../../actions/post";
import ShareModal from "../modals/ShareModal";
import { Storage } from "aws-amplify";

const PostItem = ({
  addLike,
  addDislike,
  deletePostById,
  auth,
  post: {
    id,
    text,
    name,
    avatar,
    userID,
    likes,
    dislikes,
    comments,
    updatedAt,
    image,
    categories,
  },
  showActions,
}) => {
  const [imageUrl, setImageUrl] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState(null);
  useEffect(() => {
    (async () => {
      if (image) {
        setImageUrl(await Storage.get(image));
      }
      if (avatar) {
        setAvatarUrl(await Storage.get(avatar));
      }
    })();
  }, [avatar, image]);

  return (
    <div className="post p-1 my-1">
      <div className="post-head-wrapper">
        <Link to={`/profile/${userID}`}>
          <img className="round-img avatar" src={avatarUrl} alt="avatar" />
        </Link>
        <span className="post-date">
          <p>
            Posted on <Moment format="DD/MM/YYYY">{updatedAt}</Moment>
          </p>
          <h4>by {name.slice(0, 20)}</h4>
        </span>
      </div>
      <div className="">
        <img className="post-image" src={imageUrl} alt="" />
      </div>
      <p className="post-hashtags">
        {" "}
        {categories.map((category) => (
          <small key={category}>#{category}</small>
        ))}
      </p>

      <p className="my-1 wrap-text-break">{text.slice(0, 250)}</p>

      <div className="post-actions">
        {showActions && auth.isAuthenticated === true && (
          <Fragment>
            <button
              onClick={(e) => {
                addLike(id, userID, auth.user.username);
              }}
              type="button"
              className="btn btn-light"
            >
              {"Funny "}
              <span className="px">
                {likes && likes.length > 0 && <span>{likes.length}</span>}
              </span>
            </button>

            <button
              onClick={() => {
                addDislike(id, userID, auth.user.username);
              }}
              type="button"
              className="btn btn-light"
            >
              {"Cringe "}
              <span className="px">
                {dislikes && dislikes.length > 0 && (
                  <span>{dislikes.length}</span>
                )}
              </span>
            </button>

            {auth.isAuthenticated === true &&
              !auth.loading &&
              userID === auth.user.username && (
                <button
                  onClick={(e) => deletePostById(id, auth.user.username)}
                  type="button"
                  className="btn"
                >
                  <i className="fas fa-times"></i>
                </button>
              )}
          </Fragment>
        )}
        <Link to={`/posts/${id}`} className="btn">
          <i className="fas fa-comments"></i>{" "}
          {comments && comments.length > 0 && (
            <span className="comment-count">{comments.length}</span>
          )}
        </Link>
        <ShareModal postId={id} image={imageUrl} />
      </div>
    </div>
  );
};

PostItem.defaultProps = {
  showActions: true,
};

PostItem.propTypes = {
  post: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  addLike: PropTypes.func.isRequired,
  addDislike: PropTypes.func.isRequired,
  deletePostById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  addLike,
  removeLike,
  deletePostById,
  addDislike,
  removeDislike,
})(PostItem);
