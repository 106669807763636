import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { updateProfile } from "../../actions/profile";
import Theme from "../layout/Theme";

const EditProfile = ({
  profile: { myProfile, loading },
  updateProfile,
  user,
}) => {
  const [formData, setFormData] = useState({
    website: "",
    location: "",
    bio: "",
    favourite: "",
    youtube: "",
    facebook: "",
    twitter: "",
    instagram: "",
    linkedin: "",
  });

  //create state
  const [displaySocialInputs, toggleSocialInputs] = useState(false);

  useEffect(() => {
    //set form data with current values
    setFormData({
      website: loading || !myProfile.website ? "" : myProfile.website,
      location: loading || !myProfile.location ? "" : myProfile.location,
      bio: loading || !myProfile.bio ? "" : myProfile.bio,
      favourite: loading || !myProfile.favourite ? "" : myProfile.favourite,
      youtube: loading || !myProfile.social ? "" : myProfile.social.youtube,
      facebook: loading || !myProfile.social ? "" : myProfile.social.facebook,
      twitter: loading || !myProfile.social ? "" : myProfile.social.twitter,
      instagram: loading || !myProfile.social ? "" : myProfile.social.instagram,
      linkedin: loading || !myProfile.social ? "" : myProfile.social.linkedin,
    });
  }, [loading, myProfile]);

  const {
    website,
    location,
    bio,
    favourite,
    youtube,
    facebook,
    twitter,
    instagram,
    linkedin,
  } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    updateProfile(formData, user.username);
  };

  return (
    <Fragment>
      <h1 className="large text-primary">Edit Your Profile</h1>
      <Link to="/account-settings" className="btn btn-secondary my-1">
        <i className="fas fa-sliders-h text-primary pr-1"></i>Account Settings
      </Link>
      <Theme></Theme>
      <p className="lead">
        <i className="fas fa-user"></i> Let's get some information to make your
        profile stand out
      </p>

      {/* <small>* required fields</small> */}
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div className="form-group">
          <input
            type="text"
            placeholder="Location"
            name="location"
            maxLength="100"
            value={location}
            onChange={(e) => onChange(e)}
          />
          <small className="form-text">City, State, Country, Planet</small>
        </div>
        <div className="form-group">
          <input
            type="text"
            placeholder="Favourite memes"
            name="favourite"
            maxLength="200"
            value={favourite}
            onChange={(e) => onChange(e)}
          />
          <small className="form-text">
            Please use comma separated values of your favourite meme types
          </small>
        </div>
        <div className="form-group">
          <textarea
            placeholder="A short bio of yourself"
            name="bio"
            maxLength="100"
            value={bio}
            onChange={(e) => onChange(e)}
          ></textarea>
          <small className="form-text">Tell us a little about yourself</small>
        </div>

        <div className="my-2">
          <button
            onClick={() => toggleSocialInputs(!displaySocialInputs)}
            type="button"
            className="btn btn-light"
          >
            <i className="fas fa-share-alt"> </i> Add Social Networks
          </button>
        </div>

        {displaySocialInputs && (
          <Fragment>
            <div className="form-group social-input">
              <i className="fab fa-twitter fa-2x pr-1"></i>
              <input
                type="text"
                placeholder="Twitter URL"
                name="twitter"
                value={twitter}
                onChange={(e) => onChange(e)}
              />
            </div>

            <div className="form-group social-input">
              <i className="fab fa-facebook fa-2x"></i>
              <input
                type="text"
                placeholder="Facebook URL"
                name="facebook"
                value={facebook}
                onChange={(e) => onChange(e)}
              />
            </div>

            <div className="form-group social-input">
              <i className="fab fa-youtube fa-2x"></i>
              <input
                type="text"
                placeholder="YouTube URL"
                name="youtube"
                value={youtube}
                onChange={(e) => onChange(e)}
              />
            </div>

            <div className="form-group social-input">
              <i className="fab fa-linkedin fa-2x"></i>
              <input
                type="text"
                placeholder="Linkedin URL"
                name="linkedin"
                value={linkedin}
                onChange={(e) => onChange(e)}
              />
            </div>

            <div className="form-group social-input">
              <i className="fab fa-instagram fa-2x"></i>
              <input
                type="text"
                placeholder="Instagram URL"
                name="instagram"
                value={instagram}
                onChange={(e) => onChange(e)}
              />
            </div>
            <div className="form-group social-input">
              <i className="fas fa-globe fa-2x"></i>
              <input
                type="url"
                placeholder="Website URL"
                name="website"
                maxLength="100"
                value={website}
                onChange={(e) => onChange(e)}
              />
            </div>
          </Fragment>
        )}

        <input type="submit" className="btn btn-primary my-1" />
        <Link className="btn btn-light my-1" to="/dashboard">
          Go Back
        </Link>
      </form>
    </Fragment>
  );
};

EditProfile.propTypes = {
  updateProfile: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  updateProfile,
})(withRouter(EditProfile));
