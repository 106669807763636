//fetch all data here, bring to redux state, pass to other components
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import DashboardActions from "./DashboardActions";
import ProfilePosts from "../profile/ProfilePosts";
// import ProfileStories from "../profile/ProfileStories";
/* <ProfileStories post={post} id={profile.user._id} /> */
import ProfileTop from "../profile/ProfileTop";

import PostForm from "../posts/PostForm";
// import StoryForm from "../posts/StoryForm";

const Dashboard = ({ auth: { user, loading }, myProfile, post }) => {
  //If loading
  // return
  if (loading) {
    return <Spinner />;
  } else {
    return (
      <>
        <DashboardActions></DashboardActions>
        <ProfileTop profile={myProfile} />

        <div className="dashboard-forms-wrapper">
          <PostForm></PostForm>
          {/* <StoryForm></StoryForm> */}
        </div>
        {myProfile.posts && <ProfilePosts profile={myProfile} />}
      </>
      //   return (
      //     <Fragment>
      //       <h2>It seems like you have not yet setup your profile,</h2>
      //       <br />
      //       <p>
      //         Please do so if you want to unlock all memefluencer's features such as
      //         uploading images, receiving memecash, commenting, and voting.
      //       </p>
      //       <br />
      //       <p>
      //         Also, you can read our{" "}
      //         <a
      //           href="https://www.memefluencer.space/Policy"
      //           rel="noopener noreferrer"
      //           target="_blank"
      //         >
      //           user agreement
      //         </a>
      //         , if you wish.
      //       </p>
      //       <Link className="my-3 btn btn-primary" to="/create-profile">
      //         Create Profile
      //       </Link>
      //     </Fragment>
      //   );
      // } else {
    );
  }
};

Dashboard.propTypes = {
  deleteAccount: PropTypes.func,
  auth: PropTypes.object.isRequired,
  myProfile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  myProfile: state.profile.myProfile,
  memecoins: state.profile.memecoins,
});

export default connect(mapStateToProps)(Dashboard);
