import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import PostItem from "../posts/PostItem";
import { getPostById } from "../../actions/post";
import CommentItem from "./CommentItem";
import CommentForm from "./CommentForm";
import { Storage } from "aws-amplify";
import { Helmet } from "react-helmet";

const Post = ({ auth, getPostById, post: { post, loading }, match }) => {
  useEffect(() => {
    getPostById(match.params.id);
  }, [getPostById]);

  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if (!post || !post.image || !post.image.key) return;
    //get from aws storage the url to the image
    const getImageUrl = async () => {
      const url = await Storage.get(post.image.key);
      setImageUrl(url);
    };
    getImageUrl();
  }, [post]);

  console.log(post);

  return loading || post === null ? (
    <Spinner></Spinner>
  ) : (
    <Fragment>
      <Helmet>
        <meta
          property="og:url"
          content={`https://www.memefluencer.space/posts/${post.id}`}
        />
        <meta property="og:image:secure_url" content={imageUrl} />
        <meta
          property="og:description"
          content={`Hey, check out ${post.name}'s meme`}
        />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:width" content="500" />
        <meta property="og:image:height" content="500" />
        <meta property="twitter:image" content={imageUrl} />
      </Helmet>
      <div className="comment-section">
        <Link to="/posts" className="btn">
          Back To Posts
        </Link>
        <PostItem post={post} showActions={false}></PostItem>
        <div className="comments">
          {post.comments.map((comment, i) => (
            <CommentItem key={i} comment={comment} postId={post.id} />
          ))}
        </div>
        {auth.user && <CommentForm postId={post.id} />}
      </div>
    </Fragment>
  );
};

Post.propTypes = {
  getPostById: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
  auth: state.auth,
});

export default connect(mapStateToProps, { getPostById })(Post);
