import {
  SET_AUTH,
  AUTH_ERROR,
  LOGOUT,
  ACCOUNT_DELETED,
} from "../actions/types";

const initialState = {
  isAuthenticated: null,
  loading: true, //make sure that the loading is done, when false - user is loaded
  user: null, //access user data
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_AUTH:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: payload,
      };
    case AUTH_ERROR:
    case LOGOUT:
    case ACCOUNT_DELETED:
      return {
        ...state,
        // token: null, //set token to null
        isAuthenticated: false,
        loading: false,
        user: null,
      };
    default:
      return state;
  }
}
