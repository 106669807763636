import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Storage } from "aws-amplify";
//import ProfileAbout from "./ProfileAbout"
//import DashboardActions from "../dashboard/DashboardActions";
/* {user.id === id && <DashboardActions></DashboardActions>} */
const ProfileTop = ({
  profile: { location, website, social, memecoins, name, avatar, id },
}) => {
  const [avatarImg, setAvatarImg] = useState(null);

  useEffect(() => {
    async function fetchAvatar() {
      const avatarImg = await Storage.get(avatar);
      setAvatarImg(avatarImg);
    }
    fetchAvatar();
  }, [avatar]);

  return !avatarImg ? (
    <div>Loading...</div>
  ) : (
    <div className="profile-top bg">
      <img
        className="avatar-profile"
        src={avatarImg}
        alt="Memefluencer's avatar"
      />

      <div className="user-information-wrapper">
        <h1 className="large">{name}</h1>
        <p className="my-1">
          {memecoins && <span>Memecash: {memecoins}</span>}
        </p>
        <p>{location && <span>{location}</span>}</p>
        <div className="icons my-1">
          {website && (
            <a href={website} target="_blank" rel="noopener noreferrer">
              <i className="fas fa-globe fa-2x"></i>
            </a>
          )}
          {social && social.twitter && (
            <a href={social.twitter} target="_blank" rel="noopener noreferrer">
              <i className="fab fa-twitter fa-2x"></i>
            </a>
          )}
          {social && social.facebook && (
            <a href={social.facebook} target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook fa-2x"></i>
            </a>
          )}
          {social && social.linkedin && (
            <a href={social.linkedin} target="_blank" rel="noopener noreferrer">
              <i className="fab fa-linkedin fa-2x"></i>
            </a>
          )}
          {social && social.youtube && (
            <a href={social.youtube} target="_blank" rel="noopener noreferrer">
              <i className="fab fa-youtube fa-2x"></i>
            </a>
          )}
          {social && social.instagram && (
            <a
              href={social.instagram}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram fa-2x"></i>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

ProfileTop.propTypes = {
  profile: PropTypes.object.isRequired,
};

export default ProfileTop;
