import {
  GET_POSTS,
  POST_ERROR,
  UPDATE_LIKES,
  UPDATE_DISLIKES,
  DELETE_POST,
  ADD_POST,
  GET_POST,
  ADD_COMMENT,
  REMOVE_COMMENT,
  CLEAR_POSTS,
} from "../actions/types";

const initialState = {
  posts: [],
  post: null,
  loading: true,
  error: {},
};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_POSTS:
      return {
        ...state,
        posts: { ...state.posts, [payload.userId]: payload.posts },
        loading: false,
      };
    case GET_POST:
      return {
        ...state,
        post: payload,
        loading: false,
      };
    case ADD_POST:
      return {
        ...state,
        posts: {
          ...state.posts,
          [payload.userId]: state.posts[payload.userId]
            ? [...state.posts[payload.userId], payload.newPost]
            : null,
          ALL: state.posts.ALL ? [...state.posts.ALL, payload.newPost] : null,
        },
        loading: false,
      };
    //TODO filter posts
    case DELETE_POST:
      return {
        ...state,
        posts: {
          ...state.posts,
          [payload.userId]: state.posts[payload.userId]
            ? state.posts[payload.userId].filter(
                (post) => post.id !== payload.postId
              )
            : null,
          ALL: state.posts.ALL
            ? state.posts.ALL.filter((post) => post.id !== payload.postId)
            : null,
        },
        loading: false,
      };
    case CLEAR_POSTS:
      return {
        ...state,
        posts: [],
        loading: true,
      };
    case POST_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case UPDATE_LIKES:
      return {
        ...state,
        posts: {
          ...state.posts,
          [payload.userId]: state.posts[payload.userId]
            ? state.posts[payload.userId].map((post) =>
                post.id === payload.id
                  ? { ...post, likes: payload.likes }
                  : post
              )
            : null,

          ALL: state.posts.ALL
            ? state.posts.ALL.map((post) =>
                post.id === payload.id
                  ? { ...post, likes: payload.likes }
                  : post
              )
            : null,
        },

        loading: false,
      };
    case UPDATE_DISLIKES:
      return {
        ...state,
        posts: {
          ...state.posts,
          [payload.userId]: state.posts[payload.userId]
            ? state.posts[payload.userId].map((post) =>
                post.id === payload.id
                  ? { ...post, dislikes: payload.dislikes }
                  : post
              )
            : null,

          ALL: state.posts.ALL
            ? state.posts.ALL.map((post) =>
                post.id === payload.id
                  ? { ...post, dislikes: payload.dislikes }
                  : post
              )
            : null,
        },
        loading: false,
      };
    case ADD_COMMENT:
      return {
        ...state,
        post: { ...state.post, comments: payload },
        loading: false,
      };
    case REMOVE_COMMENT:
      return {
        ...state,
        post: {
          ...state.post,
          comments: state.post.comments.filter(
            (comment) => comment.id !== payload
          ),
        },
        loading: false,
      };
    default:
      return state;
  }
}
