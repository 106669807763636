import React from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import { loadUser } from "../../actions/auth";
import "@aws-amplify/ui-react/styles.css";
//import dispatch
import { useDispatch } from "react-redux";

const Auther = ({ children, user, signOut }) => {
  const dispatch = useDispatch();
  console.log("Auther user: ", user);
  //const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  React.useEffect(() => {
    dispatch(loadUser(user.username, user.attributes));
    // setIsAuthenticated(true);
  }, [dispatch, user]);

  return <>{children}</>;
};
export default withAuthenticator(Auther);
