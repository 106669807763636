import React from "react";
import { Auth } from "aws-amplify";
import { loadUser } from "../../actions/auth";
import { useDispatch } from "react-redux";

const AuthChecker = () => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    (async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        console.log("trying fetch the user", user);
        if (user.username) {
          console.log("user", user);
          dispatch(loadUser(user.username, user.attributes));
        }
      } catch (error) {
        console.log("error", error);
      }
    })();
  }, [dispatch]);
  return <></>;
};

export default AuthChecker;
