import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Moment from "react-moment";
import { deleteComment } from "../../actions/post";
import { Storage } from "aws-amplify";

const CommentItem = ({
  postId,
  comment: { id, text, name, avatar, user, date },
  auth,
  deleteComment,
}) => {
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    (async () => {
      if (avatar) {
        setAvatarUrl(await Storage.get(avatar));
      }
    })();
  }, [avatar]);

  return (
    <div className="post bg-white p-1 my-1">
      <div>
        <Link to={`/profile/${user}`}>
          <img
            className="round-img avatar"
            src={avatarUrl}
            alt="Comment User Avatar"
          />
          <h4 className="m-1">{name}</h4>
        </Link>
      </div>
      <div>
        <p className="my-1">{text}</p>
        <p className="post-date">
          Written on <Moment format="DD/MM/YYYY">{date}</Moment>
        </p>
        {auth.user && (
          <div>
            {!auth.loading && user === auth.user.id && (
              <button
                onClick={(e) => deleteComment(postId, id)}
                type="button"
                className="btn outline-danger"
              >
                <i className="fas fa-times"></i>
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

CommentItem.propTypes = {
  postId: PropTypes.string.isRequired,
  comment: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  deleteComment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { deleteComment })(CommentItem);
