import React, { Fragment, useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import PostItem from "./PostItem";
import { getPosts } from "../../actions/post";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import categoriesSuggestion from "./categories.json";

const Posts = ({ getPosts, post }) => {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState(null);
  const [filteredPosts, setFilteredPosts] = useState(["uninitialised"]);

  const options = categoriesSuggestion.map((option) => {
    const firstLetter = option.category[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  //filter posts by category
  const filterPosts = useCallback(
    (filterWord) => {
      if (filterWord !== null) {
        setFilteredPosts(
          posts.filter((post) => post.categories.includes(filterWord.category))
        );
      } else {
        setFilteredPosts(posts);
      }
    },
    [posts]
  );
  //sort posts by their parameters
  const sortPosts = (type) => {
    switch (type) {
      case "Most popular":
        return setFilteredPosts(
          [...posts].sort(
            ({ score: previous }, { score: current }) => current - previous
          )
        );
      case "Funniest":
        return setFilteredPosts(
          [...posts].sort(
            ({ likes: previous }, { likes: current }) =>
              current.length - previous.length
          )
        );
      case "Cringest":
        return setFilteredPosts(
          [...posts].sort(
            ({ dislikes: previous }, { dislikes: current }) =>
              current.length - previous.length
          )
        );
      case "Most discussed":
        return setFilteredPosts(
          [...posts].sort(
            ({ comments: previous }, { comments: current }) =>
              current.length - previous.length
          )
        );
      case "Freshest":
        return setFilteredPosts(
          [...posts].sort(
            ({ date: previous }, { date: current }) => current - previous
          )
        );
      default:
        return setFilteredPosts(
          [...posts].sort(
            ({ date: previous }, { date: current }) => current - previous
          )
        );
    }
  };

  useEffect(() => {
    // if (posts) return;
    if (post.posts.ALL) {
      setPosts(post.posts.ALL);
      setLoading(false);
      return;
    }

    getPosts();
    setLoading(false);
  }, [getPosts, posts, post.posts.ALL]);

  return loading ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className="large text-primary text-center my-2"> Meme feed </h1>

      <div className="posts-filters">
        {/* potentially create new filter component */}
        <Autocomplete
          id="category-filter"
          onChange={(event, filterWord) => filterPosts(filterWord)}
          options={options.sort(
            (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
          )}
          groupBy={(option) => option.firstLetter}
          getOptionLabel={(option) => option.category}
          renderInput={(params) => (
            <TextField {...params} label="Filter by category" />
          )}
        />
        {/* end of filter component */}
        {/* potentially create new sort component */}
        <Autocomplete
          id="sorting"
          onChange={(event, sortOption) => sortPosts(sortOption)}
          options={[
            "Most popular",
            "Funniest",
            "Cringest",
            "Most discussed",
            "Freshest",
          ]}
          renderInput={(params) => <TextField {...params} label="Sort by..." />}
        />
        {/* end of sort component */}
      </div>

      <div className="posts">
        {/* if posts filters has not been initialised by user yet */}
        {filteredPosts[0] === "uninitialised" ? (
          posts && posts.map((post, i) => <PostItem key={i} post={post} />)
        ) : filteredPosts.length > 0 ? (
          filteredPosts &&
          filteredPosts.map((post, i) => <PostItem key={i} post={post} />)
        ) : (
          <p>No posts yet</p>
        )}
      </div>
    </Fragment>
  );
};
Posts.propTypes = {
  getPosts: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
});

export default connect(mapStateToProps, { getPosts })(Posts);
