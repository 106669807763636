import React from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const Landing = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return <Redirect to="/dashboard"></Redirect>;
  }
  return (
    <section className="landing">
      <div className="dark-overlay">
        <div className="landing-inner">
          <h1 className="x-large">Memefluencer</h1>
          <p className="lead">
            Upload your memes, let the users decide if they are funny or cringe.
          </p>
          <div className="buttons">
            <Link to="/dashboard" className="btn btn-light">
              Log in / Register
            </Link>
          </div>

          <div className="rules">
            <div className="p">
              <h3>You will never be censored</h3>
            </div>
            <div className="p">
              <h3>You will never be silenced</h3>
            </div>
            <div className="p">
              <h3>Express yourself</h3>
            </div>
            <div className="p">
              <h3>The only rule of Memefluencer. Is you don't talk about it</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Landing.propTypes = {
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Landing);
