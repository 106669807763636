import React from "react";

function Footer() {
  return (
    <div className="footer-wrapper bg-dark">
      <div className="footer">
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCKafm9w6I5efD0cgeg3M7sA"
        >
          <i className="fab fa-youtube"></i>
        </a>
        <a target="_blank" href="https://www.facebook.com/thememefluencer/">
          <i className="fab fa-facebook-f"></i>
        </a>
        <a target="_blank" href="https://www.instagram.com/memefluencer.co/">
          <i className="fab fa-instagram"></i>
        </a>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/memefluencer-pty-ltd/"
        >
          <i className="fab fa-linkedin"></i>
        </a>
        <a target="_blank" href="https://twitter.com/memefluencer_co">
          <i className="fab fa-twitter"></i>
        </a>
      </div>
    </div>
  );
}

export default Footer;
