import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import AvatarUpload from "./AvatarUpload";
import ChangeUsername from "./ChangeUsername";
import { deleteAccount } from "../../actions/profile";
// import AvatarDefaults from "./AvatarDefaults";

const AccountSettings = ({ deleteAccount, auth }) => {
  return (
    <>
      <a
        className="btn btn-secondary my-1"
        href="mailto:masondevereuxsmithenterprises@gmail.com?subject=MemefluencerFeedback"
      >
        <i className="fas fa-comment text-primary pr-1"></i> Give Us Feedback
      </a>
      <Link className="btn btn-secondary my-1 mb-2" to="/Policy">
        <i className="fas fa-file-contract text-primary pr-1"></i> User
        Agreement
      </Link>
      <ChangeUsername />
      <AvatarUpload />
      {/* <AvatarDefaults /> */}
      <button
        className="btn btn-danger btn-outline my-3"
        onClick={() => deleteAccount(auth.user.username)}
      >
        Delete My Account
      </button>
    </>
  );
};

AccountSettings.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  deleteAccount,
})(withRouter(AccountSettings));
