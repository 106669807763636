import React, { useState } from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinIcon,
  LinkedinShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
  VKShareButton,
  VKIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";

const ShareModal = ({ postId, image }) => {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <div className="btn">
        <i
          className="fas fa-share"
          onClick={() => setIsVisible(!isVisible)}
        ></i>
      </div>
      <div className={isVisible ? `modal-wrapper` : `modal-wrapper hidden`}>
        <div
          className="modal-bg"
          onClick={() => setIsVisible(!isVisible)}
        ></div>
        <div className="modal">
          <i
            className="fas fa-times m-1"
            onClick={() => setIsVisible(!isVisible)}
          ></i>
          <div>
            <img src={image} alt="Meme" className="image-preview" />
            <div className="flex flex-wrap my-3">
              <FacebookShareButton
                quote="Look what I found on memefluencer.space"
                children="Facebook"
                media={image}
                url={image}
              >
                <FacebookIcon />
              </FacebookShareButton>
              <LinkedinShareButton
                children="LinkedIn"
                source="https://memefluencer.space"
                title="Look what I found on memefluencer.space"
                media={image}
                url={image}
              >
                <LinkedinIcon />
              </LinkedinShareButton>
              <WhatsappShareButton
                children="Whatsapp"
                title="Look what I found on memefluencer.space"
                media={image}
                url={image}
              >
                <WhatsappIcon />
              </WhatsappShareButton>
              <PinterestShareButton
                children="Pinterest"
                media={image}
                url={image}
              >
                <PinterestIcon />
              </PinterestShareButton>
              <RedditShareButton
                children="Reddit"
                title="Look what I found on memefluencer.space"
                media={image}
                url={image}
              >
                <RedditIcon />
              </RedditShareButton>
              <TwitterShareButton
                children="Twitter"
                title="Look what I found on memefluencer.space"
                media={image}
                url={image}
              >
                <TwitterIcon />
              </TwitterShareButton>
              <VKShareButton
                children="VK"
                title="Look what I found on memefluencer.space"
                media={image}
                url={image}
              >
                <VKIcon />
              </VKShareButton>
              <TelegramShareButton
                children="Telegram"
                title="Look what I found on memefluencer.space"
                media={image}
                url={image}
              >
                <TelegramIcon />
              </TelegramShareButton>
            </div>
          </div>
          <p className="text-dark my-3 text-center">
            https://memefluencer.space/posts/{postId}
          </p>
        </div>
      </div>
    </>
  );
};

export default ShareModal;
