import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addPost } from "../../actions/post";
import Spinner from "../layout/Spinner";
import categoriesSuggestion from "./categories.json";

import Chip from "@material-ui/core/Chip";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const PostForm = ({ addPost, myProfile }) => {
  const [categories, setCategories] = useState([]);
  const [text, setText] = useState(" ");
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(true);
  const bodyFormData = new FormData();

  //function to submit post
  const onSubmit = (e) => {
    //stop it from continuing to the next page you probably need to
    e.preventDefault();
    const type = "MEME";
    setLoading(true);
    bodyFormData.set("text", text);
    bodyFormData.set("type", type);
    bodyFormData.append("image", image);
    bodyFormData.append("categories", categories);
    //sending image first
    const categoriesCleaned = categories.map((category) => {
      return category.category;
    });
    addPost(text, image, type, categoriesCleaned, myProfile);
    //clear text
    setText(" ");
    //clear Img
    setImage([]);
    //clear loading
    setLoading(false);
    setCategories([]);
  };

  return (
    <div className="post-form my-3">
      <div
        className="bg-primary p post-form-heading"
        onClick={() => setToggle(!toggle)}
      >
        <h3>Upload memes here</h3>
        <button className="form-toggle">
          <i
            className={toggle ? "fas fa-chevron-up" : "fas fa-chevron-down"}
          ></i>
        </button>
      </div>

      <form
        encType="multipart/form-data"
        onSubmit={onSubmit}
        className={toggle ? "form mt-1 form-show" : "form mt-1 form-hidden"}
      >
        <br></br>
        <label htmlFor="text">Description</label>
        <textarea
          name="text"
          cols="30"
          rows="2"
          placeholder="Max 250 characters"
          maxLength="250"
          value={text}
          onChange={(e) => setText(e.target.value)}
        ></textarea>
        <br></br>
        <label htmlFor="image">
          Choose image (Site will only render 1000x500px images or smaller)
        </label>
        <input
          type="file"
          id="image"
          name="image"
          className={image && image.length !== 0 ? "bg-success" : " "}
          onChange={(e) => setImage(e.target.files[0])}
          required
        />
        <Autocomplete
          multiple
          limitTags={3}
          id="categories-input"
          value={categories}
          onChange={(event, newValue) => {
            setCategories([...newValue]);
          }}
          options={categoriesSuggestion}
          getOptionLabel={(option) => option.category}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip label={option.category} {...getTagProps({ index })} />
            ))
          }
          //   style={{ color: "white" }}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ background: "white", borderRadius: "5px" }}
              //label=""
              variant="outlined"
              placeholder="Categories"
            />
          )}
        />
        {loading ? (
          <Spinner />
        ) : (
          <input
            id="submitBtn"
            type="submit"
            className="btn btn-dark my-1"
            value="Submit"
          />
        )}
      </form>
    </div>
  );
};

PostForm.propTypes = {
  addPost: PropTypes.func.isRequired,
  myProfile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  myProfile: state.profile.myProfile,
});

export default connect(mapStateToProps, { addPost })(PostForm);
