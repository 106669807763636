import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Spinner from "../layout/Spinner";
import PostItem from "../posts/PostItem";
import { getAllPostsByUserId } from "../../actions/post";

const ProfilePosts = ({
  getAllPostsByUserId,
  profile,
  post, //: { posts, loading },
}) => {
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState(null);

  useEffect(() => {
    if (post.posts[profile.id]) {
      setPosts(post.posts[profile.id]);
      setLoading(false);

      return;
    }
    getAllPostsByUserId(profile.id);
    setLoading(false);
  }, [getAllPostsByUserId, post.posts, posts, profile.id]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Fragment>
          <h1 className="large text-primary text-center posts-heading">
            {profile.name}'s Feed
          </h1>
          {posts && posts.length ? (
            <div className="posts">
              {posts.map((post) => (
                <PostItem key={post.id} post={post} />
              ))}
            </div>
          ) : (
            <p className="no-memes">
              {`${profile.name} has not uploaded any memes yet`}
            </p>
          )}
        </Fragment>
      )}
    </>
  );
};

ProfilePosts.propTypes = {
  getAllPostsByUserId: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  //   profile: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  post: state.post,
  // profile: state.profile,
  // user: state._id,
  //id: state.profile.user.id
});

export default connect(mapStateToProps, { getAllPostsByUserId })(ProfilePosts);
