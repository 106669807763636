//a function that takes piece of state that has to do with alert and action
import { SET_ALERT, REMOVE_ALERT } from '../actions/types';

const initialState = [];

export default function(state = initialState, action) {
  const { type, payload } = action;
  //action gonna contain type nad payload
  switch (type) {
    case SET_ALERT: //dispatch this type
      return [...state, payload]; //copy existing alet, add our alert to our state in an array
    case REMOVE_ALERT:
      return state.filter(alert => alert.id !== payload); //return all alerts exept one that matches payload's alert
    default:
      return state;
  }
}
