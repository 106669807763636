import React from "react";
import { Route, Switch } from "react-router-dom";
// import Register from "../auth/Register";
// import VerifyEmail from "../auth/VerifyEmail";
// import Login from "../auth/Login";
// import ForgotPassword from "../auth/ForgotPassword";
// import ResetPassword from "../auth/ResetPassword";
import Alert from "../layout/Alert";
import NotFound from "../layout/NotFound";
import Policy from "../layout/Policy";
import Dashboard from "../dashboard/Dashboard";
import CreateProfile from "../profile-form/CreateProfile";
import EditProfile from "../profile-form/EditProfile";
import AccountSettings from "../account-settings/AccountSettings";
import Profiles from "../profiles/Profiles";
import Profile from "../profile/Profile";
import Posts from "../posts/Posts";
import Post from "../post/Post";
// import Stories from "../posts/Stories";
import PrivateRoute from "../routing/PrivateRoute";

const Routes = () => {
  return (
    <section className="container">
      <Alert />
      <Switch>
        {/* <Route exact path="/login" component={Auth} /> */}
        <Route exact path="/policy" component={Policy} />
        <Route exact path="/profiles" component={Profiles} />

        <Route exact path="/profile/:id" component={Profile} />

        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute
          exact
          path="/create-profile"
          component={CreateProfile}
        ></PrivateRoute>

        <PrivateRoute
          exact
          path="/account-settings"
          component={AccountSettings}
        ></PrivateRoute>
        <PrivateRoute
          exact
          path="/edit-profile"
          component={EditProfile}
        ></PrivateRoute>

        {/* <Route exact path="/free-speech" component={Stories} /> */}
        <Route exact path="/posts" component={Posts} />
        <Route exact path="/posts/:id" component={Post} />
        <Route component={NotFound} />
      </Switch>
    </section>
  );
};

export default Routes;
